export default {
    methods: {
        fetchOrderToBuy(uuid) {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/buy/order_to_buy/${uuid}`)
                    .then(res => {
                        resolve(res.body.data)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('order_to_buy.fetching-error'))
                        reject(err)
                    })
            })
        }
    }
}
